<template>
    <div class="layout-dashboard">
        <div class="p-grid">
            <div class="p-col-12 mb-2">
                <Message v-if="state.errorMessage" severity="error">{{ state.errorMessage }}</Message>
                <Toolbar>
                    <template #start>
                        <Dropdown v-model="state.search.type" :options="state.typeList" optionLabel="name" optionValue="id" placeholder="----" class="w-full md:w-14rem ml-2" />
                        <Dropdown v-model="state.search.lot" :options="state.lotList" optionLabel="name" optionValue="id" placeholder="----" class="w-full md:w-14rem ml-2" />
                    </template>
                    <template #end>
                        <Button label="ค้นหา" icon="pi pi-search" class="p-button-info" @click="getData()" />
                    </template>
                </Toolbar>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, reactive } from 'vue';
import { useAuthStore } from '@/store/auth';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const authUserStore = useAuthStore();
const router = useRouter();
const { t } = useI18n();
const auth = computed(() => authUserStore.user);
const state = reactive({
    dialog: false,
    errorMessageH: '',
    errorMessage: '',
    loading: false,
    role: '',
    search: { type: '', lot: '', lotDt: new Date() },
    typeList: [],
    lotList: [],
});

const getData = async () => {
    try {
        state.loading = true;
        //
    } catch (error) {
        if (error.response.status === 403) {
            router.push({ name: 'Login' });
        }
        //console.log(JSON.stringify(error));
        state.errorMessage = t('0999', [error.message]);
    } finally {
        state.loading = false;
    }
};

const getCombo = async () => {
    try {
        state.loading = true;
        // const response = await LotteryService.findLotteryByIsactive();
        // state.typeList = response.data.data;
        // //
        // const response2 = await LotteryGameRoundService.findLotteryGameRoundAll();
        // state.lotList = response2.data.data;
        //
    } catch (error) {
        if (error.response.status === 403) {
            router.push({ name: 'Login' });
        }
        //console.log(JSON.stringify(error));
        state.errorMessage = t('0999', [error.message]);
    } finally {
        state.loading = false;
    }
};

onMounted(() => {
    state.role = auth.value.role;
    //
    getCombo();
    getData();
});
</script>
